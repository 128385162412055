import { Controller } from "stimulus"

export default class extends Controller {
  
  static targets = ["element"]

  connect() {}

  closeOnClick() {

    document.addEventListener("click", event => {evaluateClick(event)}, {capture: true, once: true})

    const evaluateClick = (event) => {
      const target = this.elementTarget
      const defaultClass = "d-block"
      
      const element = document.getElementById(target.getAttribute('data-element-id'))
      const ignoredElements = target.hasAttribute('data-except-id') != null ? target.getAttribute('data-except-id').split(' ') : null ;
      const exitElement = target.hasAttribute('data-exit-id') != null ? document.getElementById(target.getAttribute('data-exit-id')) : null ;
      const elementClass = target.hasAttribute('data-class') ? target.getAttribute('data-class').split(' ') : null ;

      const menuItem = target.hasAttribute('data-menu-item') ? document.getElementById(target.getAttribute('data-menu-item')) : null ;
      const menuItemClass = target.hasAttribute('data-menu-item-class') ? target.getAttribute('data-menu-item-class').split(' ') : null ;
      
      const isExit = event.composedPath().includes(exitElement)
      const withinBoundaries = event.composedPath().includes(element) && !isExit
      const isIgnored = ignoredElements.some(element => event.composedPath().includes(document.getElementById(element)) )

      if (withinBoundaries || isIgnored) {
        //console.log("click ignored")
        resetClickListener()
      } else{
        //console.log("click registered")
        elementClass == null ? removeDefaultClass(element, defaultClass) : toggleClass(element, elementClass) ;
        if(menuItem != null){toggleClass(menuItem, menuItemClass)}
        removeClickListener()
      }

    }

    const addClickListener = () => {
      document.addEventListener("click", evaluateClick, {capture: true, once: true})
    }

    const resetClickListener = () => {
      removeClickListener()
      addClickListener()
    }

    const removeClickListener = () => {
      document.removeEventListener('click', evaluateClick, true);
    }

    const removeDefaultClass = (el, cl) => {
      el.classList.remove(cl)
    }

    const toggleClass = (el, cl) => {
      cl.map(c => el.classList.toggle(c))
    }
  }
}